import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';

import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import SEO from '../components/seo';
import Countdown from '../components/blog/Countdown';

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import {
  IoLogoFacebook,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoMdPricetag,
} from 'react-icons/io';

import {
  BlogPostDetailsWrapper,
  MiscPageDetailsWrapper,
  PostHeader,
  PostHeaderImage,
  PostHeaderInfo,
  PostHeaderText,
  PostHeaderContainer,
  BlogPostWrapper,
  MiscPageWrapper,
} from './templates.style';

import SimpleSubscription from '../components/blog/SimpleSubscription';

const shortcodes = {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  IoLogoFacebook,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoMdPricetag,
  SimpleSubscription,
  Countdown,
};

const MiscPage: React.FunctionComponent = (props: any) => {
  const { data, pageContext, path } = props;

  //const posts = data.allMdx.edges;
  const { mdx } = data;
  const header = mdx ? mdx.frontmatter.header : true;
  const footer = mdx ? mdx.frontmatter.footer : true;
  const containerWidth = mdx ? mdx.frontmatter.containerWidth : '';
  const fontSizeDesktop = mdx ? mdx?.frontmatter?.fontSizeDesktop : null;
  const excerpt = mdx ? mdx.frontmatter.excerpt : null;
  const featuredImage = mdx ? mdx.frontmatter.featuredImage : null;
  const siteUrl = props.data.site.siteMetadata.siteUrl;

  const backgroundColor = mdx ? mdx.frontmatter.backgroundColor : '';
  const background = mdx.frontmatter.background
    ? mdx.frontmatter.background.publicURL
    : null;

  return (
    <Layout
      header={header === false ? false : true}
      footer={footer === false ? false : true}
    >
      <SEO
        title={mdx ? mdx.frontmatter.title : undefined}
        description={excerpt}
        featuredImage={
          featuredImage ? siteUrl + featuredImage.publicURL : undefined
        }
      />
      <MiscPageWrapper
        style={{
          marginTop: header === false ? 0 : '',
          marginBottom: footer === false ? 0 : '',
          backgroundColor: backgroundColor,
          backgroundImage: background ? `url(${background})` : '',
          backgroundSize: background ? 'cover' : '',
          backgroundPosition: background ? 'center' : '',
        }}
      >
        <MiscPageDetailsWrapper
          containerWidth={containerWidth}
          fontSizeDesktop={fontSizeDesktop}
          style={{
            '--custom-container-width': containerWidth ? containerWidth : '',
          }}
        >
          <MDXProvider components={{ ...shortcodes }}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </MiscPageDetailsWrapper>
      </MiscPageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query MiscBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        featuredImage {
          publicURL
        }
        title
        excerpt
        header
        footer
        containerWidth
        fontSizeDesktop
        backgroundColor
        background {
          id
          publicURL
        }
      }
    }
  }
`;
export default MiscPage;
